import { useEffect } from "react";
import { deleteCookie, getCookie } from "@/common/utility/cookie";
import { useAuthSettings } from "@/common/context/auth-settings-context";

export default function SignIn() {
  const authSettingsContext = useAuthSettings();

  useEffect(() => {
    try {
      const realm = getCookie("realm");
      localStorage.removeItem("authSettings");
      localStorage.removeItem("hasSeenSystemNews");
      deleteCookie("organisationId");
      deleteCookie("realm");
      deleteCookie("token");
      console.log("Signing in....");
      if (authSettingsContext) {
        // handle the potential promise rejection

        authSettingsContext.logOut(realm);
      }
    } catch (error) {
      console.log("Error while signing out:", error);
    }
  }, []);

  return <div>Signing in...</div>;
}
